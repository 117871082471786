import { memo, useRef } from "react"

import { ReactComponent as HeartIcon } from "@spatialsys/assets/icons/lucide/heart.svg"
import { ReactComponent as LayoutGridIcon } from "@spatialsys/assets/icons/lucide/layout-grid.svg"
import { ReactComponent as AutoAwesomeIcon } from "@spatialsys/assets/icons/material-outlined/auto-awesome.svg"
import { ReactComponent as GroupWorkIcon } from "@spatialsys/assets/icons/material-outlined/group-work.svg"
import { ReactComponent as NewReleaseIcon } from "@spatialsys/assets/icons/material-outlined/new-releases.svg"
import { ReactComponent as ShareIcon } from "@spatialsys/assets/icons/material-outlined/share.svg"
import { ReactComponent as UpdateIcon } from "@spatialsys/assets/icons/material-outlined/update.svg"
import { ReactComponent as WhatshotIcon } from "@spatialsys/assets/icons/material-outlined/whatshot.svg"
import { ReactComponent as AccountIcon } from "@spatialsys/assets/img/svg/account.svg"
import { ReactComponent as CloseIcon } from "@spatialsys/assets/img/svg/close.svg"
import { useGetCategoriesMenu } from "@spatialsys/react/query-hooks/menu"
import { useAppContext, useAuthState } from "@spatialsys/web/app-context"
import { cn } from "@spatialsys/web/ui"
import { useClickedOutsideOf } from "@spatialsys/web/ui/hooks"

import { useUser } from "../user/user-query-hooks"
import { Divider } from "./divider"
import { MenuItem } from "./menu-item"

export const CategoriesMenu = memo(function CategoriesMenu() {
  const isOpen = useAppContext((context) => context.state.isCategoryMenuOpen)

  const actions = useAppContext((context) => context.actions)

  const { data: categories } = useGetCategoriesMenu()

  const { user } = useUser()
  const { isAuthless, isAuthenticated } = useAuthState()

  const showTeamSpaces = !user?.myOrganization?.id

  const dropdownRef = useRef<HTMLDivElement>(null)

  useClickedOutsideOf([dropdownRef], actions.setCategoryMenuClosed)

  return (
    <div
      ref={dropdownRef}
      className={cn(
        isOpen ? "translate-x-0 shadow-[64px_0_128px_0_rgba(0,0,0,0.8)]" : "-translate-x-full",
        "fixed bottom-0 left-0 top-0 z-overlay flex flex-col items-start rounded-e-lg bg-popover ps-2 transition-transform sm:ps-6"
      )}
    >
      <button className="px-2 pb-2 pt-4" onClick={actions.setCategoryMenuClosed}>
        <CloseIcon />
      </button>

      <nav className="overflow-auto pb-4 pr-2 sm:pb-8 sm:pr-6">
        {/* Main Space Nav */}
        {isAuthenticated && !isAuthless && (
          <MenuItem href="/recent">
            <UpdateIcon className="icon" />
            Recent
          </MenuItem>
        )}
        <MenuItem href="/categories/featured-games">
          <AutoAwesomeIcon className="icon" />
          Featured
        </MenuItem>
        <MenuItem href="/trending">
          <WhatshotIcon className="icon" />
          Trending
        </MenuItem>
        <MenuItem href="/newest">
          <NewReleaseIcon className="icon" /> New
        </MenuItem>

        <Divider />
        {/* Logged In Space Nav */}
        {isAuthenticated && !isAuthless && (
          <>
            <MenuItem href="/mine">
              <AccountIcon className="icon" /> Yours
            </MenuItem>
            <MenuItem href="/loved">
              <HeartIcon className="icon" /> Loved
            </MenuItem>
            <MenuItem href="/shared">
              <ShareIcon className="icon" /> Shared
            </MenuItem>
            {!showTeamSpaces && (
              <MenuItem href="/team">
                <GroupWorkIcon className="icon" /> Team
              </MenuItem>
            )}

            <Divider />
          </>
        )}

        {/* Categories */}
        {categories?.map((category) => (
          <MenuItem href={`/categories/${category.slug}`} key={category.slug}>
            <span>{`${category.emoji}`}</span>
            {`${category.name}`}
          </MenuItem>
        ))}
        <Divider />

        <MenuItem href="/categories">
          <LayoutGridIcon className="icon" /> All Categories
        </MenuItem>
      </nav>
    </div>
  )
})
