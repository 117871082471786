import { last } from "lodash"

import { ActionT, GetActionType, makeActionCreator } from "@spatialsys/js/redux"
import { GetSocialProfileRequest } from "@spatialsys/js/sapi/types"
import type { Message } from "@spatialsys/js/types"
import { ReactAlertMessage } from "@spatialsys/unity/bridge"

export type SpaceModalT =
  | "BlockUser"
  | "EditCategories"
  | "Embed"
  | "HyperlinkConfirm"
  | "LockedMode"
  | "ManageBannedUsers"
  | "ReactAlertGeneric"
  | "RemoveUser"
  | "ReportUser"
  | "RtcSettings"
  | "SaveAsTemplate"
  | "StreamSettings"
  | "SwitchInstances"

type SpaceModalOptionT<T extends SpaceModalT, P = undefined> = Message<T, P>

type BlockUserModal = SpaceModalOptionT<"BlockUser", { displayName: string; userId: string }>
type EditCategoriesModal = SpaceModalOptionT<"EditCategories">
type EmbedModal = SpaceModalOptionT<"Embed">
type HyperlinkConfirmModal = SpaceModalOptionT<"HyperlinkConfirm", { unityAlert: ReactAlertMessage }>
type LockedModeModal = SpaceModalOptionT<"LockedMode">
type ManageBannedUsersModal = SpaceModalOptionT<"ManageBannedUsers">
type ReactAlertGenericModal = SpaceModalOptionT<"ReactAlertGeneric", { unityAlert: ReactAlertMessage }>
type RemoveUserModal = SpaceModalOptionT<"RemoveUser", GetSocialProfileRequest>
type ReportUserModal = SpaceModalOptionT<"ReportUser", { displayName: string; userId: string }>
type RtcSettingsModal = SpaceModalOptionT<"RtcSettings">
type SaveAsTemplateModal = SpaceModalOptionT<"SaveAsTemplate">
type StreamSettingsModal = SpaceModalOptionT<"StreamSettings">
type SwitchInstancesModal = SpaceModalOptionT<"SwitchInstances">

export type SpaceModalOption =
  | BlockUserModal
  | EditCategoriesModal
  | EmbedModal
  | HyperlinkConfirmModal
  | LockedModeModal
  | ManageBannedUsersModal
  | ReactAlertGenericModal
  | RemoveUserModal
  | ReportUserModal
  | RtcSettingsModal
  | SaveAsTemplateModal
  | StreamSettingsModal
  | SwitchInstancesModal
export type SpaceModalsState = SpaceModalOption[]
export const initialSpaceModalsState: SpaceModalsState = []

export enum SpaceModalsActionType {
  CloseSpaceModal = "CloseSpaceModal",
  OpenSpaceModal = "OpenSpaceModal",
}

type CloseSpaceModal = ActionT<SpaceModalsActionType.CloseSpaceModal, SpaceModalT>
type OpenSpaceModal = ActionT<SpaceModalsActionType.OpenSpaceModal, SpaceModalOption>

export const SpaceModalsActions = {
  closeSpaceModal: makeActionCreator<CloseSpaceModal>(SpaceModalsActionType.CloseSpaceModal),
  openSpaceModal: makeActionCreator<OpenSpaceModal>(SpaceModalsActionType.OpenSpaceModal),
}
export type SpaceModalsAction = GetActionType<typeof SpaceModalsActions>

export function spaceModalsReducer(state: SpaceModalsState, action: SpaceModalsAction): SpaceModalsState {
  switch (action.type) {
    case SpaceModalsActionType.CloseSpaceModal:
      return state.filter((modal) => modal.type !== action.payload)
    case SpaceModalsActionType.OpenSpaceModal:
      // Don't allow opening the same modal multiple times
      if (last(state)?.type === action.payload.type) {
        return state
      }
      return state.concat(action.payload)
    default:
      return state
  }
}
