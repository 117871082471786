import { AxiosInstance } from "axios"

export type GetAnalyticsRequest = {
  beginAt: number
  endAt: number
  worldId: string
}

export type GetTemplateSpacesAnalyticsRequest = {
  beginAt: number
  endAt: number
}

export type GetAnalyticsResponse = {
  averageVisitDuration: number
  creatorCommission: number
  dailySales: {
    creatorCommission: number
    date: string
    totalSales: number
  }[]
  timeUnit: string
  totalSales: number
  visitDurationBySpaceID: {
    [spaceID: string]: {
      /**
       * Five time buckets: `less than a minute`, `1-10 minutes`, `10-60 minutes`, `60-120 minutes`, `120+ minutes`
       */
      bucket: string
      count: number
      order: number
    }[]
  }
  visits: number
  visitsBySpaceID: {
    [spaceID: string]: {
      count: number
      time: string
    }[]
  }
}

export function createAnalyticsEndpoint(client: AxiosInstance) {
  return {
    getAnalytics: async function (args: GetAnalyticsRequest): Promise<GetAnalyticsResponse> {
      const { worldId, beginAt, endAt } = { ...args }
      const response = await client.get<GetAnalyticsResponse>(`/worlds/${worldId}/overview`, {
        params: {
          begin_at: beginAt,
          end_at: endAt,
        },
      })
      return response.data
    },
    getTemplateSpacesAnalytics: async function (
      args: GetTemplateSpacesAnalyticsRequest
    ): Promise<GetAnalyticsResponse> {
      const { beginAt, endAt } = { ...args }
      const response = await client.get<GetAnalyticsResponse>(`/overview`, {
        params: {
          begin_at: beginAt,
          end_at: endAt,
        },
      })
      return response.data
    },
  }
}
