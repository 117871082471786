/**
 * If user is in private mode or there is a storage restriction, `localStorage` itself throws an error.
 * Thus, always wrap accessing `localStorage` in a try/catch block.
 * `JSON.parse` and `JSON.stringify` can also throw, too.
 *
 * @see https://stackoverflow.com/questions/30481516/iframe-in-chrome-error-failed-to-read-localstorage-from-window-access-deni
 */

export const Storage = {
  DISABLE_ADSENSE: "disable_adsense",
  DEBUG_SETTINGS: "debug_settings",
  STORAGE_RETURN_URL_KEY: "return_url",
  STORAGE_LOCAL_LOG_LEVEL_KEY: "local_log_level",
  STORAGE_REMOTE_LOG_LEVEL_KEY: "remote_log_level",
  STORAGE_FINISHED_TUTORIAL_KEY: "tutorial",
  STORAGE_AUTHLESS_BADGES_KEY: "badges_to_claim",
  STORAGE_SPACE_LIST_TAB_KEY: "space_list_tab",
  STORAGE_UNITY_WEBGL_VERSION_KEY: "unity_webgl_version",
  SPATIAL_MEDIA_SETTINGS: "SpatialMediaSettings",
  HAS_DISPLAYED_VIDEO_PERMISSIONS_MODAL: "has_displayed_video_permissions_modal",
  MIXPANEL_DEBUG: "mixpanel_debug",
  REDUX_DEBUG: "redux_debug",
  LAST_PUSH_NOTIF_PERMISSION_REQUEST_DATE_KEY: "lastPushNotifPermissionRequestDate",
  LAST_USED_EMOTE_PANEL_TAB: "lastUsedEmotePanelTab",
  AUTHLESS_DISPLAY_NAME_LOCAL_STORAGE_KEY: "authlessDisplayName",
  AUTHLESS_AVATAR_LOCAL_STORAGE_KEY: "authlessAvatar",
  /**
   * Set when coming to a space's URL with via the `avatarId` parameter, used for automatically
   * setting an authless user's avatar to something outside of the SAPI-provided default set.
   */
  AUTHLESS_RPM_ID_LOCAL_STORAGE_KEY: "authlessRpmId",
  UPDATING_PLUS_MEMBERSHIP: "updating_plus_membership",
  HAS_CLOSED_UPDATING_MEMBERSHIP_TOAST: "has_closed_updating_membership_toast",
  HAS_SHOWN_UNIVERSAL_SHOP_NEW_FEATURE_BADGE: "has_shown_universal_shop_new_feature_badge",
  HAS_SHOWN_FULLSCREEN_MODE_NEW_FEATURE_BADGE: "has_shown_fullscreen_mode_new_feature_badge",
  HAS_SHOWN_FULLSCREEN_MODE_TOAST: "has_shown_fullscreen_mode_toast",

  // TODO - tighten this type, it should only be string, or explicitly parsed as JSON
  // but we're using number and bools in some places.
  fetch<T = string>(key: string, defaultValue?: T, { raw = true }: { raw?: boolean } = {}): T | undefined {
    try {
      if (typeof localStorage === "undefined") return defaultValue

      const value = localStorage.getItem(key)
      if (value === null) {
        if (defaultValue === undefined) {
          return value as unknown as T
        }
        return defaultValue
      }
      if (!raw) {
        return JSON.parse(value) as T
      }
      return value as unknown as T
    } catch (error) {
      return defaultValue
    }
  },
  setItem(key: string, value: any) {
    try {
      localStorage?.setItem(key, value)
    } catch {
      // no-op
    }
  },
  removeItem(key: string) {
    try {
      localStorage?.removeItem(key)
    } catch {
      // no-op
    }
  },
}

export type StorageT = typeof Storage
