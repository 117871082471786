import { ChannelResponse, MessageResponse } from "stream-chat"

import {
  AdministratorPermission,
  AppearanceCustomizationState,
  BackpackItemState,
  InvitedGuestUserData,
  ShopItemDisplayStatusState,
  ShopItemState,
} from "@spatialsys/unity/app-state"

import { FeedStatsWithOutcome } from "./spaces/spaces"

export const enum PlatformHeaderString {
  Android = "ANDROID_MOBILE",
  Web = "WEB",
  iOS = "IOS",
}

/**
 * Somewhat standardized pagination metadata for SAPI responses. However it is possible that some responses different
 *
 * Make sure to confirm the structure of the response before using this type
 */
export type PaginationResponseMetadata = {
  count: number
  skip: number
  totalCount: number
}

export type PaginationRequestMetadata = {
  count?: number
  skip?: number
}

export type CreatorMetadata = {
  appearanceCustomization: AppearanceCustomizationState
  avatarData: AvatarData
  avatarImageURL: string
  displayName: string
  id: string
  username: string
}

export type SpaceMetadata = {
  activeUserCount: number
  createdAt: string
  customVideo?: SapiVideo
  description?: string
  fullScreenModeEnabled?: boolean
  id: string
  isCustomThumbnail?: boolean
  joinCount: number
  /**
   * String representation of a date. Only defined for Unity SDK spaces that have been published.
   * This value is updated everytime the unity space package is updated.
   */
  lastUpdated?: string
  likeCount: number
  liked: boolean
  live?: boolean
  lobbyType: SAPILobbyType
  logo?: string
  name: string
  ownerID: string
  posterVideo?: SapiVideo
  published: boolean
  publishedAt?: string
  roomAdmins: string[]
  /** All thumbnails available for a space **/
  roomThumbnails: SAPIRoomThumbnail
  /** True if the space is the Creator Toolkit sandbox **/
  sandbox?: boolean
  seoDescription?: string
  seoTitle?: string
  shareID?: string
  /** Unity Package SKU. Valid only for Creator Toolkit spaces **/
  sku?: string
  slug: string
  tags?: string[]
  /** The thumbnail to use when previewing a space (i.e. in the spaces list) **/
  thumbnail: string
}

export type SpaceAndCreator = {
  creator: CreatorMetadata
  space: SpaceMetadata
}

export type SpacePreviewResponse = SpaceAndCreator & {
  rank?: number
}

export interface ISAPIFieldError {
  error: string
  field: string
}

export type SAPIErrorCode =
  | "ACCOUNT_LOCKED"
  | "BANNED"
  | "CONSECUTIVE_UNDERSCORES"
  | "DUPLICATE_USERNAME"
  | "INPUT_TOO_LONG"
  | "INPUT_TOO_SHORT"
  | "INVALID_ACCESS"
  | "INVALID_CHARACTERS"
  | "INVALID_CREDENTIAL"
  | "INVALID_NFT"
  | "INVALID_PARAMETER"
  | "INVALID_SSO_REQUEST"
  | "LEADING_UNDERSCORE"
  | "NO_LETTER"
  | "NOT_VERIFIED"
  | "ORGANIZATION_NOT_FOUND"
  | "PROHIBITED_WORDS"
  | "RESERVED_WORDS"
  | "ROOM_NOT_FOUND"
  | "STORAGE_LIMIT_REACHED"
  | "TICKET_ALREADY_EXISTS"
  | "TOKEN_GATED"
  | "TRAILING_UNDERSCORE"
  | "USER_UNAUTHORIZED"
  | "USERNAME_TAKEN"

export interface ISAPIError {
  code: SAPIErrorCode
  customData?: any
  display: boolean
  fields?: ISAPIFieldError[]
  message: string
  statusCode: number
}

export interface JoinFailMessage {
  bannedUntil: number
  hasWallet: boolean
  message: string
  roomName: string
  // Unix milliseconds
  tokenGateConfig: TokenGateConfig
}

/**
 * When SAPI returns an error status code, the response body
 * will be this type.
 */
export type SapiErrorResponseBody = {
  errors: ISAPIError[]
  trace: string
}

/**
 * Deprecated. An error format thrown on certain legacy SAPI endpoints.
 */
export type SapiErrorResponseBodyLegacy<T> = {
  code: string
  errors: ISAPIError[]
  message: T
}

export interface SAPIRoomThumbnail {
  cubemapGetUrl: string
  customGetUrl: string
  expiresAt: number
  persistentGetUrl: string
  transientGetUrl: string
}

export interface SAPIAppearanceCustomization {
  profileColor: string
}

type UserAvatarStyle = "CREATOR_TOOLKIT" | "READY_PLAYER_ME" | "REALISTIC_RPM_HYBRID" | "REALISTIC"

export interface ReadyPlayerMeThumbnails {
  lowerBody?: string
  upperBody?: string
}

export interface AvatarData {
  activeAvatarStyle?: UserAvatarStyle
  avatarBody?: string
  avatarID?: string
  avatarUserID?: string
  filename?: string
  readyPlayerMeThumbnails?: ReadyPlayerMeThumbnails
  readyPlayerMeUrl?: string
  shirtColorOverride?: string
  skinColor?: string
  skinColorOverride?: string
}

export type AvatarSdkDataOnboarding = Pick<AvatarData, "avatarID" | "avatarUserID"> | null

export interface SAPIParticipantProfile {
  appearanceCustomization: SAPIAppearanceCustomization
  clientPlatform: string
  displayName: string
  id: string
  playerColor: string
  profilePicURL: string
}

export const CONTROL_TREATMENT_VALUE = "control"
export const ENABLED_TREATMENT_VALUE = "enabled"

/**
 * The keys match the name of the treatment in Retool
 *
 * When new treatments are added in Retool, this type should be updated, as well as `ITreatments` and `convertTreatmentsFromSapi` function
 */
export type TreatmentKeysRetool = "authlessRpmAvatars"

/**
 * The treatments object from SAPI (defined in Retool). All treatment values are passed as a string
 */
export type TreatmentsRetool = Record<TreatmentKeysRetool, string>

/**
 * The treatments object from SAPI (defined in Retool) that are included in the bootstrap endpoint.
 * All treatment values are passed as a string
 */
export type TreatmentsInBootstrapRetool = Record<Extract<TreatmentKeysRetool, "authlessRpmAvatars">, string>

export type SAPIBootstrapResponse = {
  treatments: TreatmentsInBootstrapRetool
  upgradeMessage: string
}

export type TreatmentsInBootstrapRetoolFormatted = TreatmentsInBootstrapRetool

export type BootstrapTreatments = Omit<SAPIBootstrapResponse, "treatments"> & {
  treatments: TreatmentsInBootstrapRetoolFormatted
}

/**
 * All possible values of user permissions defined in SAPI `common_types.go:L159`
 */
export enum UserPermissions {
  ACCESS_BILLING_PAGE = "ACCESS_BILLING_PAGE",
  ASSOCIATE_DOMAIN_TO_ORG = "ASSOCIATE_DOMAIN_TO_ORG",
  CAN_ACCESS_ADMIN_PAGE = "CAN_ACCESS_ADMIN_PAGE",
  CAN_CHANGE_PASSWORD = "CAN_CHANGE_PASSWORD",
  SPEECH_CAPTIONING = "SPEECH_CAPTIONING",
  UNLIMITED_MEETING_DURATIONS = "UNLIMITED_MEETING_DURATIONS",
  UPGRADE_PRO = "UPGRADE_PRO",
}

/**
 * This is slightly different from what's in Unity app state (namely, some fields aren't flattened like `roomThumbnails`)
 * We need to move SAPI models to a shared package and ideally auto-generate the interfaces from the SAPI code
 * But for now, throwing this here
 */
export interface SAPIRoom {
  active: boolean
  activeParticipants?: SAPIParticipantProfile[]
  description: string
  directlyInvitedGuests: { [key: string]: InvitedGuestUserData }
  id: string
  isInstanceable: boolean
  isPublishedToExplore: boolean
  likedSpace: boolean
  likes: number
  lobbyType: SAPILobbyType
  name: string
  organizationOwnerID?: string
  organizationOwnerName?: string
  organizations: string[]
  owner: { displayName: string; userID: string }
  ownerID?: string
  participants: any
  polymerVersion: number
  publicLink?: boolean
  restrictedPermissions: AdministratorPermission[]
  roomAdmins: string[]
  /** All thumbnails available for a space **/
  roomThumbnails: SAPIRoomThumbnail
  sandbox: boolean
  shareID?: string
  shareSetting: SAPIShareSetting
  slug: string
  /** The thumbnail to use when previewing a space (i.e. in the spaces list) **/
  thumbnail: string
  thumbnails: string[]
  tileSize?: SAPITileSize
  timeLastJoined?: string
  version: number
  views: number
  /** Present on Creator Toolkit spaces */
  worldID?: string
}

export enum SAPITileSize {
  Large = "large",
  None = "",
}

export enum SAPILobbyType {
  None = "",
  Private = "PRIVATE_LOBBY",
  Public = "PUBLIC_LOBBY",
}

export type SAPIShareSetting = "ORG" | "PRIVATE" | "PUBLIC_LINK" // Public Link being phased out in DEV-5172

/** The value of the enum is the value of the actual query parameter */
export enum SAPISpaceType {
  Community = "community",
  Lobby = "lobby",
  Org = "org",
  Personal = "personal",
  PublicPark = "publicPark",
  Search = "search",
}

export interface LiveswitchMediaSettings {
  maxFrameRate: number
  maxHeight: number
  maxWidth: number
  mediaType: string
}

export interface LiveswitchConfig {
  appID: string
  mediaSettings: LiveswitchMediaSettings[]
  url: string
}

/** GET /rooms/${roomId} */
export interface GetRoomArgs {
  roomId: string
}

/** Response from GET /rooms/${roomID} */
export interface GetRoomResponse {
  roomData: SAPIRoom
}

/** Response from GET /files/{fileId} */
export interface GetFileResponse {
  file: SAPIFile
  fileUrls: SAPIFileUrls
  nftMetadata?: NftMetadata
}

/**
 * If the user has decided to hide it in the CMS menu or not.
 * If hidden, it doesn't count as part of the user's storage quota.
 */
export type CMSVisibility = "HIDDEN" | "VISIBLE"

/**
 * ExternalModel contains information necessary for an external model processor
 */
export interface ExternalModel {
  id: string
  provider: string
}

/**
 * Metadata for the converted files
 */
export interface ProcessedFile {
  contentType: string
  createdAt: string
  externalModel: ExternalModel
  location: string
  size: number
  target: string
  textureMaxDimension: number
}

export interface SAPIFile {
  //
  /** Array of fileIds */
  Children: string[] | null
  /** Date of file creation */
  CreatedAt: string
  /** Used for files that will never be shown in Content Menu */
  HideFromContentMenu: boolean
  /**
   * Used for file collections to remember which room to upload to
   */
  OrigRoomID: string
  /** A file may be nested under another file */
  ParentID: string
  /** Date of when file was uploaded */
  UploadedAt: string
  contentSource: {
    /** Currently not used for anything */
    LastDownloadedAt: string
    Link: string
    Thumbnail: string
    Type: string
  }
  /** todo: implement all the content types e.g. image/png, image/jpeg etc */
  contentType: string
  /**  Deprecated in favor of Raw/Processed */
  fileLocation: string
  /** If image, how high it is */
  height: number
  id: string
  name: string
  numPages: number
  orgOwnerID: string
  rawLocation: string
  /** Deprecated in favor of putting this on the room model */
  roomOwnerID: string
  size: number
  uploadState: "AWAITING" | "FAILED" | "PENDING" | "UPLOADED"
  uploaderID: string
  /**
   * Used for any file that the User decides to hide via the CMS tab
   */
  visibility: CMSVisibility
  /** If image, how wide it is in pixels */
  width: number
}

/**
 * Cloudfront URLs for accessing SAPI files
 */
export interface SAPIFileUrls {
  expiresAt: number
  getUrl: string
  getUrlFileType: string
  modelCompressedUrl?: string
  modelLowResUrl?: string
  putUrl: string
  rawUrl: string
  /** Only exists for images */
  thumbnailUrl?: string
}

export enum SaleType {
  BUY_NOW,
  AUCTION,
  NOT_LISTED_FOR_SALE,
}

export type Currency = "ETH" | "SOL" | "WETH"

export enum NftMarketPlace {
  MagicEden = "MagicEden",
  OpenSea = "OpenSea",
}

export interface NftMetadata {
  collection: string
  creator: string
  description: string
  externalLink: string
  marketplace: NftMarketPlace
  nftPrice: NftPrice
  owner: string
}

export interface NftPrice {
  currency?: Currency
  price?: number
  priceLabel?: string
  saleType?: SaleType
}

/** Response from POST /nft/verify */
export interface VerifyNFTResponse {
  buttonText?: string
  /** The following 2 properties are present if `ownershipVerfied` is false */
  linkToBuy?: string
  ownershipVerified: boolean
}

/**
 * Response from POST /auth/v1/register/ethereum
 */
export interface AuthenticateWithEthereumResponse {
  /** nonce to sign */
  message: string
  /**  if true, we need to collect email (i.e. it's a new MM account with no email associated with it yet) */
  requireEmail: boolean
}

/**
 * Arguments to PUT /auth/v1/register/ethereum
 */
export interface CompleteRegistrationArgs {
  email: string
  publicAddress: string
  signature: string
}

/**
 * Response from PUT /auth/v1/register/ethereum
 */
export interface CompleteRegistrationResponse {
  /** Firebase custom token */
  token: string
}

/**
 * Response from POST /auth/v1/verify/signature
 */
export interface VerifySignatureResponse {
  /** Firebase custom token */
  token: string
}

/**
 * Args for POST /auth/v1/verify/email
 */
export interface RequestEmailVerificationArgs {
  email: string
  publicAddress?: string
}

/**
 * Args for PUT /auth/v1/verify/email
 */
export interface VerifyEmailArgs {
  ticket: string
}

/** Response from POST /auth/v1/verify/email */
export interface VerifyEmailResponse {
  /** Whether the email was successfully verified or not */
  verified: boolean
}

/** Response from GET /space/v1/rooms/:roomID/chat */
export interface JoinChatResponse {
  active: boolean
  /** Explicitly typing channelType and channelName as strings
   * Since there is no need to accommodate custom return types for these properties
   * Stream Chat provides some presets for a channel as channelType
   * For our purposes, it will likely be "SpatialSpacesV1"
   * Which is the same as the "instance" channel type but with file uploads */
  channel: ChannelResponse & { channelName: string; channelType: string }

  /** The ~20 most recent messages in the chat */
  messages: MessageResponse[]

  /** Token to connect to Stream chat with */
  token: string

  userID: string
}

export interface CustomNftEnvironment {
  chain: BlockchainIdentifier
  contractAddress: string
  creatorName: string
  marketplaceButtonText: string
  marketplaceUrl: string
  modelUrl: string
  name: string
  presetUrl: string
  thumbnailUrl: string
  tokenID: string
}

/** Args for GET /stripe/checkout */
export interface GetStripeCheckoutArgs {
  term: string
  tier: string
}

/** Response from GET /stripe/checkout */
export interface GetStripeCheckoutResponse {
  sessionId: string
}

/** Response from POST /stripe/embedded-checkout */
export interface CreateEmbeddedStripeCheckoutResponse {
  clientSecret: string
}

/** Response from GET /stripe/portal */
export interface GetStripePortalResponse {
  sessionUrl: string
}

export enum ContractType {
  ERC1155 = "erc1155",
  ERC721 = "erc721",
}

export enum BlockchainIdentifier {
  Ethereum = "ethereum",
  Matic = "matic",
  Solana = "solana",
}

export interface TokenGateConfig {
  /** If `true`, allow any token within the contract.
   * ex: token gate to owners of any cryptopunk, instead of just the owner of a specific punk
   * should only be used if the contractType is erc721
   */
  anyToken: boolean
  blockchainIdentifier: BlockchainIdentifier
  contractAddress: string
  contractType: ContractType
  disabled: boolean
  purchaseLink: string
  quantity: number
  tokenID: string
  tokenName: string
}

export type GetSocialProfileRequest =
  | {
      userID: string
      username?: never
    }
  | {
      userID?: never
      username: string
    }

export interface SocialProfile extends SocialProfileEditableData {
  bannerSpaceID: string
  bannerURL: string
  linkDiscord: string
  linkInstagram: string
  linkLinkedin: string
  linkOpensea: string
  linkTiktok: string
  linkTwitter: string
  numFollowers: number
  numFollowing: number
  numSpaces: number
  userID: string
}

type SocialLinks = {
  linkDiscord: string
  linkInstagram: string
  linkLinkedin: string
  linkOpensea: string
  linkTiktok: string
  linkTwitter: string
  linkWebsite: string
  usernameDiscord: string
  usernameInstagram: string
  usernameLinkedin: string
  usernameOpensea: string
  usernameTiktok: string
  usernameTwitter: string
}

/* eslint-disable typescript-sort-keys/interface */
export type SocialProfileV2 = {
  about: string
  avatarImageURL: string
  bannerURL: string
  displayName: string
  isPrivate: boolean
  numFollowers: number
  numFollowing: number
  profileBackgroundColor: string
  socialLinks: SocialLinks
  userID: string
  username: string

  emotes: AvatarAnimation[]
  totalEmoteCount: number

  spaces: SpaceAndCreator[]
  totalSpacesCount: number

  badges: Badge[]
  totalBadgeCount: number

  avatars: Avatar[]
  totalAvatarCount: number
}
/* eslint-enable typescript-sort-keys/interface */

export interface PatchSocialProfileDataRequest {
  profileData: SocialProfileEditableData
  userID: string
}

export interface SocialProfileEditableData {
  about?: string
  appearanceCustomization?: SAPIAppearanceCustomization
  avatarImageURL?: string
  displayName?: string
  isPrivate?: boolean
  linkWebsite?: string
  profileBackgroundSpaceID?: string
  pronoun?: string
  username?: string
  usernameDiscord?: string
  usernameFacebook?: string
  usernameInstagram?: string
  usernameLinkedin?: string
  usernameOpensea?: string
  usernameTiktok?: string
  usernameTwitter?: string
}

export interface FollowerInfo {
  avatarImageURL: string
  displayName: string
  userID: string
  username: string
}

export type JoinedSpaces = {
  ID: string
  instanceID: string
  isPrivate: boolean
  name: string
  shareID: string
  slug: string
}

export type ChatChannelResponse = {
  name: string
  token: string
  type: string
}

export type Friend = {
  /**
   * If the user is online
   */
  active: boolean
  channel?: ChatChannelResponse
  displayName: string
  id: string
  /**
   * If its a new friend request
   */
  new: boolean
  playerColor?: string
  profilePicUrl: string | undefined
  /**
   * The spaces that the user is current in
   */
  spaces?: JoinedSpaces[]
  /**
   * ACTIVE = Friends
   * BLOCKED = Can't send current user friend requests
   * NONE = Not friends and not blocked
   * PENDING = Current user has received a friend request from this user
   * SENT = Current user has sent a friend request to this user
   */
  status: "ACTIVE" | "BLOCKED" | "NONE" | "PENDING" | "SENT"
  username: string
}

export type PublicFriend = Omit<Friend, "channel" | "spaces">

export type GetFriendsResponse = PaginationResponseMetadata & {
  friends: Friend[]
}

export interface GetFollowersRequest {
  limit?: number
  skip?: number
  userID: string
}

export interface GetFollowersResponse {
  followers: FollowerInfo[]
}

export interface GetFollowingRequest {
  limit?: number
  skip?: number
  userID: string
}

export interface GetFollowingResponse {
  followings: FollowerInfo[]
}

export interface FollowUserRequest {
  userID: string
}

export interface UnfollowUserRequest {
  userID: string
}

export interface RemoveFollowerRequest {
  userID: string
}

export interface SetProfileBackgroundSpaceIDRequest {
  profileBackgroundSpaceID: string
}

export const enum EmoteType {
  Animation = "Animation",
  Emoji = "Emoji",
  Unity = "Unity",
}

/**
 * EmoteType used by the new /v2/users/me/emotes endpoints.
 * Helps type emotes returned by the following endpoints:
 * - GET /v2/users/me/emotes
 * - PUT /v2/users/me/emotes/:emoteID
 * - GET /v2/users/me/recent-emotes
 */
export type EmoteTypeV2 = "animation" | "emoji" | "unity"

/**
 * EmotePayload is the minimal payload required to identify an emote.
 * To render a emote, it should be parsed into an Emote object.
 * */
export interface EmotePayload {
  emoteType: EmoteType
  identifier: string
}

export interface SetRecentEmotesRequest {
  recentEmotes: EmotePayload[]
  userId: string
}

export const enum AssetSourceType {
  BuiltIn = "BuiltIn",
  NFT = "NFT",
  UnityPackage = "UnityPackage",
}

export const enum SpaceTemplateCategory {
  Abstract = "Abstract",
  All = "All",
  Collectibles = "Collectibles",
  Community = "Community",
  /** Deprecated */
  Free = "Free",
  Gallery = "Gallery",
  Learning = "Learning",
  Meeting = "Meeting",
  Outdoor = "Outdoor",
  /** Deprecated */
  /** Creator Toolkit (Unity Package) environments */
  Packages = "Packages",
  Party = "Party",
  Performance = "Performance",
  Pro = "Pro",
}

export const enum SpaceTemplateType {
  Event = "Event",
  Gallery = "Gallery",
}

export const enum AssetBuildStatus {
  /** One or more platform bundles failed */
  Failed = "Failed",
  /** Package metadata (name, thumbnail, etc) received and platform bundles are being built */
  InProgress = "InProgress",
  /** Not applicable */
  None = "",
  /** Package was successfully uploaded to S3 and queued on CI */
  Submitted = "Submitted",
  /** All platform bundles are ready to load */
  Success = "Successful",
}

export interface SpaceTemplate {
  /** Optional, number of hotspot seats */
  capacity?: number
  /** Different asset types can appear in other categories */
  categories: string[]
  category: SpaceTemplateCategory
  /** Collectibles only */
  contractAddress?: string
  creatorID: string
  creatorName: string
  currentVersion: number
  description: string
  environmentAssetType: AssetSourceType
  environmentType: SpaceTemplateType
  galleryFrameCount?: number
  hideForPlusUsers?: boolean
  id: string
  isPro: boolean
  latestSuccessfulVersion: number
  marketplaceButtonText?: string
  marketplaceUrl?: string
  name: string
  nftChain?: BlockchainIdentifier
  /** Points to a JSON/room template that contains the environment information. Used mainly for custom NFT environments. */
  presetUrl: string
  progress: AssetBuildStatus
  tokenID?: string
  variants: SpaceTemplateVariant[]
}

export interface SpaceTemplateVariant {
  id: string
  miniThumbnail: string
  /** hex */
  miniThumbnailColor: string
  thumbnail: string
}

export type PackageWorldDetail = {
  worldID: string
  worldSpaces: { name: string; shareID: string; spaceID: string }[] | null
}

export const enum AvatarScope {
  Universal = "Global",
  World = "World",
}

export const enum AvatarCategory {
  Abstract = "Abstract",
  Animal = "Animal",
  Fantasy = "Fantasy",
  Human = "Human",
  Robotic = "Robotic",
  Unspecified = "Unspecified",
}

export interface AvatarStats {
  hasEffects: boolean
}

export interface Avatar {
  assetType: AssetSourceType
  category: AvatarCategory
  creatorID: string
  creatorName: string
  description: string
  id: string
  name: string
  /** AKA usage context: determines where this avatar can be loaded and used */
  scope: AvatarScope
  stats: AvatarStats
  thumbnail: string
}

export const enum AvatarAnimationType {
  Emote = "Emote",
  Idle = "Idle",
  LocomotionSet = "LocomotionSet",
  Sit = "Sit",
}

export interface AvatarAnimationStats {
  hasEffects: boolean
  lengthSeconds: number
}

interface EmoteBase {
  animationType: AvatarAnimationType
  assetType: AssetSourceType
  emoteType: EmoteTypeV2
  id: string
  /** Published packages only */
  stats?: AvatarAnimationStats
  used: boolean
}

export interface Emoji extends EmoteBase {
  emoteType: Extract<EmoteTypeV2, "emoji">
}
export interface AvatarAnimation extends EmoteBase {
  creatorID: string
  creatorName: string
  description: string
  emoteType: Exclude<EmoteTypeV2, "emoji">
  itemID: string
  name: string
  /**
   * Determines whether the animation should be shown in UI components like the emotes picker
   * If false, the animation can still be played via hotkeys/the recents bar.
   */
  shownInMenu: boolean
  /** Used when there's no video thumbnail provided */
  thumbnail?: string
  videoThumbnail?: string
}

export interface PrefabObjectStats {
  hasEffects: boolean
  isInteractable: boolean
}

export interface PrefabObject {
  assetType: AssetSourceType
  creatorID: string
  creatorName: string
  description: string
  id: string
  name: string
  stats: PrefabObjectStats
  thumbnail: string
}

export const enum UnlockableType {
  Streak = "Streak",
}

export type ProfileUnlockable = {
  current: number
  id: string
  /** Date */
  resetsAt: number
  type: UnlockableType
  unlocks: ProfileUnlockableItem[]
}

export type ProfileUnlockableItem = {
  cost: number
  /** A given cost may have more than one unlockable item */
  ids: string[]
  /** If false, this item has just been newly unlocked */
  read: boolean
  unlocked: boolean
}

/** Feature flags from ConfigCat */
export type FeatureFlags = {
  adinPlayAds: boolean
  alwaysExpandQuestPillIfFirstQuest: boolean
  /**
   * List of paths to use for default authless avatars, separated by `|` character.
   * Of the format `name-slug|name-slug|...`, for example:
   * `male-black-dreads-638e6994474e25e55be5d246|female-black-dreads-6389056fa3a085619eaf9b32`
   *
   */
  authlessAvatarBodies: string
  bugReporter: boolean
  categoryCurator: boolean
  coinsExpressCheckout: boolean
  crossInstanceStreaming: boolean
  disableInstanceSwitcherIfFirstQuest: boolean
  discountedItems: string
  editMode: boolean
  externalGamesAdsenseAds: boolean
  feedCurator: boolean
  firstQuestSpacePath: string
  gamAds: boolean
  h5Ads: boolean
  hideThirdPartyGamesInHomeFeed: boolean
  internalUser: boolean
  iosSpaceSubarus: boolean
  jumpCut: boolean
  /**
   * At the time of creation, it is also used on SAPI, in route /api/v1/rooms/noauth/:roomID
   */
  noAuthUserJoinSpaceWithoutShareId: boolean

  /**
   * List of spaces where mic and camera are disabled for non-hosts, separated by the `,` character.
   */
  nonHostsMicAndCamDisabledSpaces: string
  reportWebNetworkErrorsToSentry: boolean
  requiresPlayClick: boolean
  requiresTipaltiToMonetize: boolean
  sendMobileJsLogsToUnity: boolean
  shortScreenShareTime: boolean
  /** Comma-separated list of space IDs */
  skipFirstTutorial: string
  spaceComments: boolean
  spacePageAdTile: boolean
  spaceSeoFields: boolean
  /** Stringified JSON: { id: string, shareId: string }. The string "null" is used for null value. */
  spatialStoreSpace: string
  studioSetCoinsPrice: boolean
  thirdPartyGames: boolean
  thirdPartyGamesFeedPosition: number
  universalStorePercentDiscountShown: boolean
  webPlatformChallenges: boolean
  webProfileAvatarEmotes: boolean
  webShowDebugMenu: boolean
  webglVersion: string
}

// Badges

type BadgeBase = {
  badgeIconURL: string
  createdAt: string
  description: string
  id: string
  name: string
  /** ex: 2023-03-04T00:04:57.704328Z */
  rewardedAt?: string
  updatedAt: string
  worldID: string
  worldName: string
}

export type Badge =
  | BadgeBase
  | (BadgeBase & {
      shareID: string
      spaceID: string
      spaceName: string
    })

export type GetBadgeRequest = {
  badgeId: string
}

export type GetBadgeResponse = Badge

export type ClaimBadgeRequest = {
  badgeId: string
  spaceID: string
}

export type UserBadge =
  | BadgeBase
  | (BadgeBase & {
      shareID: string
      spaceID: string
      spaceName: string
    })

export enum FeedComponentType {
  SpacesGrid = "spaces-grid",
  SpacesRow = "spaces-row",
  SpacesVideoHighlights = "spaces-video-highlights",
}

/**
 * All possible data types for a feed component.
 */
export type FeedComponentData = SpacesGridFeedComponentData &
  SpacesRowFeedComponentData &
  SpacesVideoHighlightsFeedComponentData

export type FeedComponent = {
  data: FeedComponentData
  type: FeedComponentType
}

type FeedTitleSegment = {
  label: string
  link?: string
}

export type FeedTitle = FeedTitleSegment[]

export type SapiVideo = {
  mp4: string
  poster: string
  posterVideoFirstFrame: string
  webm: string
  webmLowRes: string
}

/**
 * Generic data that the back-end can use to add additional properties to the
 * space join context analytics payload.
 */
type AnalyticsContext = Record<string, boolean | number | string>

export type SpacesRowFeedComponentData = {
  analyticsContext: AnalyticsContext
  id: string
  /** Used to link to the section's category */
  link: string
  spaces: SpaceAndCreator[]
  title: FeedTitle
}

export type SpacesGridFeedComponentData = SpacesRowFeedComponentData & {
  numRows: 1 | 2 | 3
}

export type SpacesVideoHighlightsFeedComponentData = {
  analyticsContext: AnalyticsContext
  id: string
  spaces: SpaceAndCreator[]
  title: FeedTitle
}

export enum FeedSectionType {
  SpacePosters = "spaces-posters",
  SpaceThumbnails = "spaces-thumbnails",
}

export type FeedSection = {
  id: string
  numRows?: number
  overrideId?: string
  title: FeedTitle
  type: FeedSectionType
}

export type FeedConfigResponse = {
  createdAt: string
  description: string
  lastUpdatedAt: string
  variants: Array<[{ sections: FeedSection[] }, FeedStatsWithOutcome?]>
  version: number
}

export type FeedCategory = {
  createdAt?: string
  description?: string
  excludeSpaces?: string[]
  excludeTags?: string[]
  isPrivate: boolean
  name: string
  slug: string
  sorting?: string[]
  spaces: string[]
  tags: string[]
  updatedAt?: string
}

export type AllCategoriesResponse = FeedCategory[]

export type FeedCategoryDetailsSpace = {
  /* Creator's username */
  creator: string
  id: string
  name: string
  thumbnail: string
}

export type FeedCategoryDetailsTag = {
  id: string
  name: string
  spaces: FeedCategoryDetailsSpace[]
}

export type CategoryDetailsResponse = {
  createdAt: string
  description?: string
  excludeSpaces?: FeedCategoryDetailsSpace[]
  excludeTags?: FeedCategoryDetailsTag[]
  isPrivate: boolean
  name: string
  slug: string
  sorting?: FeedCategoryDetailsSpace[]
  spaces?: FeedCategoryDetailsSpace[]
  tags?: FeedCategoryDetailsTag[]
  updatedAt: string
}

export type ItemScope = "global" | "world"
export type ItemType = "avatar" | "avatarAttachment" | "currency" | "emote" | "generic" | "prefabObject"

export type WorldCurrencyPrice = {
  currencyID: string
  currencyName: string
  /** Amount of currency required to purchase the item */
  price: number
  /** Quantity of the item to purchase */
  quantity: number
}
export type CoinsPrice = {
  currencyName: string
  /** Amount of coins required to purchase the item */
  price: number
  /** Quantity of the item to purchase */
  quantity: number
}
/** Used to update/create the price of an item with world currency. `currencyID` and `currencyName` are not required */
export type WorldCurrencyPriceUpdate = Pick<WorldCurrencyPrice, "price" | "quantity"> | null
/** Used to update/create the price of an item with coins */
export type CoinsPriceUpdate = Pick<CoinsPrice, "price" | "quantity"> | null

export type Item = {
  /** The Unity asset ID, exists only for items that have a Unity asset */
  assetSKU?: string
  coinsPrice: CoinsPrice | null
  consumable: boolean
  consumableCoolDownMillis?: number
  consumableDurationMillis?: number
  createdAt: string
  creatorID: string
  /** If defined, use custom thumbnail over defaultThumbnailUrl */
  customThumbnailUrl?: string
  defaultThumbnailUrl: string
  description: string
  externalID?: string
  id: string
  name: string
  published: boolean
  scope: ItemScope
  shopListed: boolean
  stackable: boolean
  type: ItemType
  updatedAt: string
  worldCurrencyPrice: WorldCurrencyPrice | null
  worldID: string
  worldName: string
}

export type WorldMemberRole = "admin" | "member" | "owner"

export type World = {
  createdAt: string
  description: string
  displayName: string
  id: string
  members: (CreatorMetadata & { role: WorldMemberRole })[]
  name: string
  spaces: (SpaceAndCreator & { subscriptionTier: PricingPlanId })[]
  updatedAt: string
}

export type Wallet = {
  spatialCoinsBalance: number
  userID: string
}

export type UnityPackageType =
  | "Avatar"
  | "AvatarAnimation"
  | "AvatarAttachment"
  | "Environment"
  | "PrefabObject"
  | "Space"
  | "SpaceTemplate"

export type UnityPackageVersion = {
  buildStatus: AssetBuildStatus
  dateUploaded: string
  sdkVersion: string
  unityVersion: string
  /** Integer, in bytes */
  uploadSize: number
  version: number
}

export type UnityPackage = {
  creatorID: string
  creatorName: string
  currentVersion: number
  description: string
  latestSuccessfulVersion: number
  name: string
  packageSource: "Unity"
  packageType: UnityPackageType
  sku: string
  thumbnail: string
  /** In chronological order */
  versions: UnityPackageVersion[]
}

export type BackpackItem = BackpackItemState & {
  id: string
}

export type ShopItem = ShopItemState & {
  id: string
}

export type ShopItemDisplayStatus = ShopItemDisplayStatusState & {
  id: string
}

/**
 * A consolidated shop item state with its display status.
 * The display status is set by the creator,
 * and is used to determine whether the item should be entirely disabled in the shop.
 * This is different from the shop item `enabled` state, which is a derived Unity state for determining whether the item can be purchased
 */
export type ShopItemWithDisplayStatus = ShopItem & {
  displayStatus: ShopItemDisplayStatusState
}

export type DiscountedItemFromConfigCat = {
  discountEndDate: string
  itemId: string
  originalPrice: number
}

export type DiscountItem = {
  discountEndDate: string
  originalPrice: number
  position: number
}

export type DiscountedItems = DiscountedItemFromConfigCat[]

export type DiscountedItemsDict = Record<string, DiscountItem>

export type ShopItemWithDisplayStatusAndDiscount = Partial<DiscountedItemFromConfigCat> & ShopItemWithDisplayStatus

export type ShopItemWithDiscount = Partial<Pick<DiscountedItemFromConfigCat, "discountEndDate" | "originalPrice">> &
  ShopItem

export type CheckoutRequest = {
  idempotencyKey: string
  itemID: string
  quantity: number
  spaceID: string
}

export type CoinPackage = {
  amount: number
  baseAmount: number
  bonusAmount: number
  bonusPercent: number
  currency: string
  icon: string
  popular: boolean
  price: string
  priceID: string
}

/**
 * Possible states of a ledger transaction
 */
export const enum LedgerTransactionStatus {
  /**
   * A terminal state to indicate processing has failed.
   * Failed state will only occur when transaction failed unexpectedly.
   * e.g. transaction that failed because of insufficient balance will be recorded as "FAILED".
   */
  Failed = "FAILED",
  /** Initiated. Waiting for a request from the user to proceed. */
  Pending = "PENDING",
  /** User has made a request to proceed with the mtx. */
  Processing = "PROCESSING",
  /** Initial transaction attempt failed; trying again to process the request. */
  Retry = "RETRY",
  /** A terminal state to indicate transaction was successful. */
  Success = "SUCCESS",
}

/**
 * Default status is `NOT_ENROLLED`
 */
export type CreatorProgramEnrollmentStatus = "BLOCKED" | "ENROLLED" | "NOT_ENROLLED" | "PENDING"

export type Payout = {
  /** Amount in coins */
  coinsBalance: number
  /** Amount in USD cents */
  payoutBalanceInUSDCents: number
  /** Datestring */
  processedAt?: string
  requestedAt: string
  status: "FAILED" | "PENDING" | "PROCESSING" | "RETRY" | "SUCCESS"
  transactionID: string
}

export type MicroTransaction = {
  /** Value of the transaction in coins */
  amount: number
  /** Amount in coins in cents that the creator received */
  commissionInCents: number
  item: Item
  /** Datestring */
  purchasedAt: string
  /** integer */
  quantity: number
  transactionID: string
}

export type GetCategoriesMenuResponse = {
  emoji: string
  name: string
  slug: string
}[]

export type ThirdPartyGame = {
  author: string
  categories: string[]
  description: string
  embedUrl: string
  image: string
  order: number
  richDescription?: string
  slug: string
  title: string
}

export type ExternalGameRating = {
  bestRating: number
  ratingCount: number
  ratingValue: number
  worstRating: number
}

export type ExternalGame = {
  aggregateRating?: ExternalGameRating
  author: string
  categories: string[]
  description: string
  embedUrl: string
  image: string
  richContent: string
  slug: string
  title: string
}

type FeatureType =
  | "FEATURE_ADDITIONAL"
  | "FEATURE_ANALYTICS"
  | "FEATURE_CONCURRENT_USERS"
  | "FEATURE_CREATOR_TOOLS"
  | "FEATURE_PACKAGE_LIMITS"
  | "FEATURE_SCREEN_SHARING"
  | "FEATURE_SHARING"
  | "FEATURE_SUPPORT"
  | "FEATURE_TEMPLATES"
  | "FEATURE_UPLOAD_LIMIT"

export type Feature = {
  description: string
  limit?: number
  name: string
  remaining?: number
  type: FeatureType
}

export type FeatureSet = {
  features: Feature
  name: PricingPlanId
}

export type PricingPlanFeatures = {
  description?: string
  description_additional?: string
  enabled?: boolean
  limit?: number
  name: string
  shortenedDescription?: string
  shortenedFeatureName?: string
}

export type PricingPlanFeatureCategory = {
  features: PricingPlanFeatures[]
  name: string
  shortenedName?: string
}

export type PricingPlanId =
  // eslint-disable-next-line @typescript-eslint/sort-type-constituents
  "" | "PLUS_LEGACY" | "FREE" | "FREE_UGC" | "PRO" | "BUSINESS" | "ENTERPRISE"

export type PaidPricingPlanId = Exclude<PricingPlanId, "" | "FREE_UGC" | "FREE" | "PLUS_LEGACY">

export type BillingCycle = "ANNUAL" | "MONTHLY"
export type SupportFeatureLimits = "PRIORITIZED_ZENDESK" | "PRIVATE_SLACK"
export type AnalyticsFeatureLimits = "ADVANCED_UNLIMITED" | "BASIC_30_DAYS" | "CUSTOM_MIXPANEL"

/**
 * Space Subscription Pricing Plan
 */
export type PricingPlan = {
  annualDiscountPercent: number
  annualPrice: number
  annualPriceTag?: string
  buttonLabel: string
  currency: string
  description: string
  featureCategories: PricingPlanFeatureCategory[]
  featureCategorySummaries: string[]
  id: Exclude<PricingPlanId, "PLUS_LEGACY">
  limits: SpaceLimitsResponse
  monthlyPrice: number
  monthlyPricingTag?: string
  name: string
  nextId: PricingPlanId
  priceLabel?: string
  priceTag?: string
  recommended: boolean
}

/**
 * Space Subscription Pricing Plans response
 */
export type PricingPlansResponse = {
  label: string
  prices: PricingPlan[]
  savingsAmountLabel: string
  savingsLabel: string
}

export type SpaceLimitsResponse = {
  accessRestricted: boolean
  advancedHostToolsAllowed: boolean
  analyticsFeatures: AnalyticsFeatureLimits[]
  canUseProTemplates: boolean
  concurrentUsersLimit: number
  fullScreenModeAllowed: boolean
  goLiveAllowed: boolean
  nextPlan: PricingPlanId
  packageSizeCurrent: number
  packageSizeLimit: number
  packageSizeSoftLimit: number
  plan: PricingPlanId
  screenShareDailyTimeLimit: number
  supportFeatures: SupportFeatureLimits[]
  tokenGatingAllowed: boolean
  uploadStorageCurrent: number
  uploadStorageLimit: number
  uploadStorageSoftLimit: number
  usesProTemplate: boolean
  vanityURLAllowed: boolean
  webEmbedAllowed: boolean
  webcamAllowed: boolean
}

export type PurchaseSubscriptionRequest = {
  billingCycle: BillingCycle
  plan: Extract<PricingPlanId, "BUSINESS" | "PRO">
  spaceID: string
}

export type PurchaseSubscriptionResponse = {
  clientSecret: string
  sessionID: string
  transactionID: string
}

export type GetStripeSpaceSubCheckoutArgs = {
  spaceID: string
  term: string
  tier: string
}

export type SpaceSubscription = {
  endAt: string
  plan: PricingPlanId
  renewAt?: string | null
  spaceID: string
  startAt: string
  status: string
}
export type SpaceSubscriptionResponse = SpaceSubscription[]
